export const routes = {
  olympiadRegistrationFormSSO: "/olympiad-registration-form-sso",
  iframeSSO: "/iframe-sso",
  popupSSO: "/popup-sso",
  zoomAuth: "/zoom-auth",

  login: "/",
  svp: {
    base: "/svp",
    login: "/svp/login",
    forgotPassword: "/svp/forgot-password",
  },
  internal: {
    base: "/internal",
    login: "/internal/login",
    forgotPassword: "/internal/forgot-password",
  },
  centre: {
    base: "/centre",
    login: "/centre/login",
    forgotPassword: "/centre/forgot-password",
  },
  school: {
    base: "/school",
    login: "/school/login",
    logout: "/school/logout",
    signUp: "/school/signup",
    resetPassword: "/school/reset-password/:uuid/:token/:timestamp",
    forgotPassword: "/school/forgot-password",
  },
  partner: {
    base: "/partner",
    login: "/partner/login",
    logout: "/partner/logout",
    resetPassword: "/partner/reset-password/:uuid/:token/:timestamp",
    forgotPassword: "/partner/forgot-password",
  },

  logout: "/logout",
  signUp: "/signup",
  help: "/help",
  resetPassword: "/reset-password/:uuid/:token/:timestamp",
  forgotPassword: "/forgot-password",
  createBetaPassword: "/beta/create-password/:id/:token",
  createPassword: "/create-password/:uuid/:token/:timestamp",

  ryanSchool: {
    signup: "/ryan/signup",
    signin: "/ryan/signin",
  },

  error: "/E88iUJijnM",

  profile: "/",
} as const
