import { CssBaseline, theme } from "@suraasa/placebo-ui"
import AppWrapper from "components/AppWrapper"
import GlobalState from "components/GlobalState"
import Redirect from "components/Redirect"
import ErrorPage from "components/shared/ErrorPage"
import ExternalNavigate from "components/shared/ExternalNavigate"
import LazyLoadedRoute from "components/shared/LazyLoadedRoute"
import React, { useEffect } from "react"
import { ThemeProvider } from "react-jss"
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom"
import { Platforms, Product } from "utils/constants"
import { GA } from "utils/googleAnalytics"
import { getPlatformURL } from "utils/helpers"
import { routes } from "utils/routes"
import ToastProvider from "utils/toast/ToastProvider"
import HybridLogin from "views/auth/HybridLogin"
import IframeSSO from "views/auth/IframeSSO"
import Logout from "views/auth/Logout"
import PlatformLogout from "views/auth/PlatformLogout"
import PopupSSO from "views/auth/PopupSSO"
import SignUp from "views/auth/SignUp"

const ForgotPassword = React.lazy(() => import("views/auth/ForgotPassword"))
const ResetPasswordHandler = React.lazy(
  () => import("views/auth/ResetPasswordHandler")
)
const RyanSignIn = React.lazy(() => import("views/auth/ryan/v2/SignIn"))
const RyanSignUp = React.lazy(() => import("views/auth/ryan/v2/Signup"))
const SchoolForgotPassword = React.lazy(
  () => import("views/auth/school/ForgotPassword")
)
const SchoolLogin = React.lazy(() => import("views/auth/school/Login"))
const SchoolSignUp = React.lazy(() => import("views/auth/school/Signup"))
const Help = React.lazy(() => import("views/help"))

const SentryTest = React.lazy(() => import("views/SentryTest"))

const redirects = [
  {
    routes: [routes.svp.login, routes.svp.forgotPassword],
    platform: Platforms.svp,
  },
  {
    routes: [routes.partner.login, routes.partner.forgotPassword],
    platform: Platforms.olympiadPartner,
  },
  {
    routes: [routes.centre.login, routes.centre.forgotPassword],
    platform: Platforms.centreAdmin,
  },
  {
    routes: [routes.internal.login, routes.internal.forgotPassword],
    platform: Platforms.serviceAdmin,
  },
] as const

function App() {
  useEffect(() => {
    GA.init()
  }, [])

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <ToastProvider domRoot={document.body}>
          <CssBaseline />
          <Routes>
            {redirects.map(({ routes: redirect, platform }) =>
              redirect.map(route => (
                <Route
                  key={route}
                  element={
                    <Redirect
                      to={routes.login}
                      search={{
                        platform:
                          new URLSearchParams(window.location.search).get(
                            "platform"
                          ) || platform,
                      }}
                    />
                  }
                  path={route}
                />
              ))
            )}

            <Route element={<AppWrapper />} path="/">
              <Route
                element={
                  <LazyLoadedRoute>
                    <SentryTest />
                  </LazyLoadedRoute>
                }
                path={routes.error}
              />
              <Route element={<PopupSSO />} path={routes.popupSSO} />

              <Route element={<IframeSSO />} path={routes.iframeSSO} />

              <Route element={<HybridLogin />} path={routes.login} />
              <Route element={<Logout />} path={routes.logout} />

              <Route
                element={
                  <LazyLoadedRoute>
                    <RyanSignIn />
                  </LazyLoadedRoute>
                }
                path={routes.ryanSchool.signin}
              />
              <Route
                element={
                  <LazyLoadedRoute>
                    <RyanSignUp />
                  </LazyLoadedRoute>
                }
                path={routes.ryanSchool.signup}
              />

              <Route element={<SignUp />} path={routes.signUp} />
              <Route
                element={
                  <LazyLoadedRoute>
                    <ForgotPassword product={Product.learning} />
                  </LazyLoadedRoute>
                }
                path={routes.forgotPassword}
              />
              <Route
                element={
                  <LazyLoadedRoute>
                    <ForgotPassword product={Product.internal} />
                  </LazyLoadedRoute>
                }
                path={routes.internal.forgotPassword}
              />
              <Route
                element={
                  <LazyLoadedRoute>
                    <ForgotPassword product={Product.center} />
                  </LazyLoadedRoute>
                }
                path={routes.centre.forgotPassword}
              />
              <Route
                element={
                  <LazyLoadedRoute>
                    <ForgotPassword product={Product.partner} />
                  </LazyLoadedRoute>
                }
                path={routes.partner.forgotPassword}
              />

              <Route
                element={
                  <LazyLoadedRoute>
                    <ResetPasswordHandler />
                  </LazyLoadedRoute>
                }
                path={routes.resetPassword}
              />
              <Route
                element={
                  <LazyLoadedRoute>
                    <ResetPasswordHandler createNew />
                  </LazyLoadedRoute>
                }
                path={routes.createPassword}
              />

              {/* <Route
                element={<CreateBetaPassword />}
                path={routes.createBetaPassword}
              /> */}

              {/* This is a fail-safe just in case user lands on /reset-password. They need to be redirected */}
              <Route
                element={<Navigate to={routes.forgotPassword} />}
                path="/reset-password"
              />

              {/* Authentication routes for School platform */}
              <Route
                element={
                  <LazyLoadedRoute>
                    <SchoolLogin />
                  </LazyLoadedRoute>
                }
                path={routes.school.login}
              />

              <Route
                element={<PlatformLogout platform={Platforms.school} />}
                path={routes.school.logout}
              />
              <Route
                element={
                  <LazyLoadedRoute>
                    <SchoolSignUp />
                  </LazyLoadedRoute>
                }
                path={routes.school.signUp}
              />

              <Route
                element={
                  <LazyLoadedRoute>
                    <SchoolForgotPassword />
                  </LazyLoadedRoute>
                }
                path={routes.school.forgotPassword}
              />

              {/* Authentication routes for Partner platform */}
              {/* <Route
                element={
                  <LazyLoadedRoute>
                    <PartnerLogin />
                  </LazyLoadedRoute>
                }
                path={routes.partner.login}
              /> */}
              <Route
                element={
                  <LazyLoadedRoute>
                    <ForgotPassword product={Product.partner} />
                  </LazyLoadedRoute>
                }
                path={routes.partner.forgotPassword}
              />

              <Route
                element={
                  <PlatformLogout platform={Platforms.olympiadPartner} />
                }
                path={routes.partner.logout}
              />

              <Route
                element={
                  <ExternalNavigate to={getPlatformURL("learn", "/home")} />
                }
                path="/dashboard"
              />
              <Route
                element={
                  <ExternalNavigate to={getPlatformURL("learn", "/profile")} />
                }
                path="/dashboard/profile"
              />
              <Route
                element={
                  <ExternalNavigate
                    to={getPlatformURL("learn", "/profile")}
                    search={{ edit: "true" }}
                  />
                }
                path="/dashboard/profile/edit"
              />
              <Route
                element={
                  <ExternalNavigate
                    to={getPlatformURL("learn", "/skill-profile")}
                  />
                }
                path="/dashboard/skill-profile"
              />
              <Route
                element={
                  <ExternalNavigate
                    to={getPlatformURL("learn", window.location.pathname)}
                  />
                }
                path="/profile/*"
              />
              <Route
                element={
                  <ExternalNavigate
                    to={getPlatformURL(
                      "learn",
                      "/profile/skill-profile/:username"
                    )}
                    params={["username"]}
                  />
                }
                path="/skill-profile/:username"
              />
              <Route
                element={
                  <ExternalNavigate
                    to={getPlatformURL("learn", "/profile/skill-profile/")}
                  />
                }
                path="/skill-profile/*"
              />

              {/*
               * These routes are wrapped in GlobalState because apart from PrivateRoute
               * all other routes are public but even a logged in user can open them,
               * if a logged user opens these routes, user photo, name has to be visible in
               * Navbar the data for which is in global state.
               */}
              <Route element={<GlobalState />} path="/">
                <Route
                  element={
                    <LazyLoadedRoute>
                      <Help />
                    </LazyLoadedRoute>
                  }
                  path={routes.help}
                />

                <Route element={<ErrorPage />} path="*" />
              </Route>

              <Route element={<ErrorPage />} path="*" />
            </Route>
          </Routes>
        </ToastProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App
